@mixin computer-xl {
  @media screen and (min-width: 1920px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 650px) and (max-width: 769px) {
    @content;
  }
}

@mixin tablet-m {
  @media screen and (min-width: 520px) and (max-width: 650px) {
    @content;
  }
}

@mixin tablet-s {
  @media screen and (min-width: 431px) and (max-width: 520px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 431px) {
    @content;
  }
}

@mixin mobile-s {
  @media screen and (max-width: 400px) {
    @content;
  }
}




