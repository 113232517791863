@import "mat-input";
@import "mat-menu";
@import "mat-select";
@import "mat-multi-select";
@import "mat-expansion-panel";
@import "mat-paginator";
@import "mat-tab";
@import "mat-accordion";
@import "theme";
@import "mat-chip";
@import "skeleton";

@font-face {
  font-family: 'Montserratarm';
  src: url('/assets/fonts/montserrat-armenian/Montserratarm-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Montserratarm';
  src: url('/assets/fonts/montserrat-armenian/Montserratarm-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserratarm';
  src: url('/assets/fonts/montserrat-armenian/Montserratarm-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserratarm';
  src: url('/assets/fonts/montserrat-armenian/Montserratarm-ExtraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserratarm';
  src: url('/assets/fonts/montserrat-armenian/Montserratarm-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserratarm';
  src: url('/assets/fonts/montserrat-armenian/Montserratarm-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserratarm';
  src: url('/assets/fonts/montserrat-armenian/Montserratarm-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserratarm';
  src: url('/assets/fonts/montserrat-armenian/Montserratarm-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserratarm';
  src: url('/assets/fonts/montserrat-armenian/Montserratarm-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

* {
  box-sizing: border-box;
  font-family: 'Montserratarm', sans-serif;
  padding: 0;
  margin: 0;

  &:before {
    box-sizing: border-box;
  }

  &:after {
    box-sizing: border-box;
  }
}

body {
  overflow: hidden;
}

.form-field-view-mode {
  .label {
    font-size: 13px;
    display: flex;
    align-content: center;
    align-items: center;
    //height: 17px;
    margin-bottom: 3px;
    &-text {
      margin-right: 6px;

    }
    .icon {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
      margin-top: 3px;
    }
  }
  .value {
    display: flex;
    border: 1px solid #212121;
    border-radius: 4px;
    font-size: 14px;
    min-height: 36px;
    padding: 7px 12px;
    align-items: center;
    white-space: pre-line;
  }
}

.sidenav .mat-drawer-inner-container {
  overflow-y: auto !important;
}


.view {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #ffffff !important;
  }
}

.create {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #EEEEEE !important;
  }
}

.required {
  color: red;
  font-size: 17px;
  margin-left: 3px;
}

.mat-mdc-option {
  min-height: 36px !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: unset;
  background: #DADADA;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #AEAEAE;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #AEAEAE;
}

.pagination_count_form-field .mdc-text-field--outlined .mat-mdc-form-field-infix,
.mdc-text-field--no-label .mat-mdc-form-field-infix{
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 10px;
}

// Status block
.status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 0;

  .status-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-bottom: 5px;

    &-color {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      .checked-icon {
        width: 12px;
      }
    }

    &-name {
      font-size: 10px;
      text-align: center;
      line-height: normal;
    }

    &:first-child .status-wrapper-color:before {
      content: "";
      position: absolute;
      top: 25%;
      left: 3px;
      width: 36px;
      height: 1px;
      background-color: red;
      transform: translateY(-50%) rotate(45deg);
    }
  }
}
.search-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: transparent !important;
  box-shadow: unset !important;
}
.cdk-overlay-pane {
  max-width: 100vw !important;
}
