.customised-mat-menu {
  min-width: 298px !important;
  max-width: 298px !important;
  margin-left: -10px;
  min-height: 393px !important;
  overflow: hidden !important;
  background-color: #F9F9F9 !important;
}

.customised-calendar .mat-calendar-controls {
  margin: 0;
}

.customised-calendar .mat-calendar-table-header th {
  padding: 0;
}

.customised-calendar .mat-calendar-content {
  padding: 0 8px 0 8px;
}

.mat-mdc-menu-content {
  padding: 0 !important;
  height: 100% !important;
}

.customised-calendar .mat-calendar-header {
  padding: 2px 8px 0 8px;
}

.customised-mat-menu-phone {
  min-width: 298px !important;
  max-width: fit-content !important;
  overflow: hidden !important;
}

.customised-mat-filter-menu.mat-mdc-menu-panel {
  max-width: fit-content !important;
}

.customised-mat-action-menu.mat-mdc-menu-panel {
  max-width: fit-content !important;
}

.customised-mat-filter-menu.mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: -1px 10px 15px 0px rgb(0 0 0 / 0%), 0px 20px 20px 0px rgb(0 0 0 / 0%), 0px 19px 20px 0px rgb(0 0 0 / 9%) !important;
}

.customised-manage-entity-list-menu.mat-mdc-menu-panel {
  max-width: fit-content !important;
  background: transparent !important;
}

.customised-freezed-list-menu.mat-mdc-menu-panel {
  border-radius: 8px !important;
}

.customised-save-screen-menu.mat-mdc-menu-panel {
  background: transparent !important;
  box-shadow: unset !important;
  max-width: fit-content !important;
}

.customised-manage-entity-list-menu.mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: unset !important;
}

.status-customised-mat-menu {
  width: 60px !important;
  min-width: 60px !important;
}
