.customised-mat-select {
  width: 100%;
}

.customised-mat-select .mdc-text-field--filled {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.customised-mat-select .mat-mdc-select-arrow-wrapper {
  display: none;
}

.customised-mat-select .mdc-text-field {
  padding: 0 10px;
}

.customised-mat-select .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding: 0;
}

.customised-mat-select .mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
  padding: 0;
}

.customised-mat-select .mat-mdc-form-field-infix {
  min-height: 36px;
}

.customised-mat-select .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 8px;
  z-index: 1;
}

.customised-mat-select .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  margin-top: 8px;
}

.customised-mat-select .mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align::before {
  font-size: 10px;
}

.customised-mat-select .mdc-text-field--filled .mdc-floating-label {
  font-size: 18px;
}

.customised-mat-select .mat-icon {
  font-size: 22px !important;
  margin-left: 7px;
  cursor: pointer;
}

.customised-mat-select .mat-mdc-form-field-error {
  font-size: 10px;
}

.mat-mdc-form-field-required-marker {
  display: none;
}

.customised-mat-select .mat-mdc-select-arrow-wrapper {
  transform: translateY(0) !important;
}

.customised-mat-select .mat-mdc-select-value {
  margin-top: 22px;
}

.mat-pseudo-checkbox {
  display: none !important;
}

.mat-mdc-option .mat-icon {
  margin-right: 0 !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
  background-color: unset !important;
  border-top: solid 1px #007a72 !important;
  border-bottom: solid 1px #007a72 !important;
}
.mat-mdc-checkbox .mdc-checkbox__ripple {
  width: 19px;
}
.mat-mdc-checkbox-ripple {
  width: 19px;
}
