.customised-mat-multiselect {
  width: 100%;
}
.customised-mat-multiselect .mdc-text-field--filled{
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.customised-mat-multiselect .mdc-text-field {
  padding: 0 10px;
}
.customised-mat-multiselect .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding: 0;
}
.customised-mat-multiselect .mat-mdc-form-field-infix {
  min-height: 36px;
}
.customised-mat-multiselect .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 8px;
  z-index: 1;
}
.customised-mat-multiselect .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control  {
  //margin-top: 25px;
}
//.customised-mat-multiselect .mdc-evolution-chip-set .mdc-evolution-chip {
//  margin-top: 16px;
//}
.customised-mat-multiselect .mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
  padding: 0;
}
.customised-mat-multiselect .mdc-evolution-chip-set__chips {
  padding-top: 8px;
}
.customised-mat-multiselect .filled-chip-grid .mdc-evolution-chip-set__chips {
  padding-top: 2px;
}
.customised-mat-multiselect .mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align::before {
  font-size: 10px;
}
.customised-mat-multiselect .mdc-text-field--filled .mdc-floating-label {
  font-size: 18px;
}
.customised-mat-multiselect .mat-icon {
  font-size: 19px !important;
  margin-left: 0 !important;
  cursor: pointer;
}
.customised-mat-multiselect .mat-mdc-form-field-error {
  font-size: 10px;
}

.customised-mat-multiselect .mat-mdc-form-field-required-marker {
  display: none;
}
.customised-mat-multiselect .mat-mdc-select-arrow-wrapper {
  transform: translateY(0) !important;
}
.customised-mat-multiselect .mat-mdc-select-value {
  margin-top: 10px;
}
.customised-mat-multiselect .mat-mdc-select {
  display: none;
}
.customised-mat-multiselect .mdc-evolution-chip-set {
  //margin-top: 15px;
}
.customised-mat-multiselect .mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 25px;
}
.customised-mat-multiselect .mdc-evolution-chip__cell {
  padding-right: 6px;
}
.customised-mat-multiselect .mat-mdc-standard-chip .mdc-evolution-chip__text-label {
  font-size: 12px;
}
.customised-mat-multiselect .mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--trailing {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.customised-mat-multiselect .cancel{
  margin-left: 0 !important;
  font-size: 16px !important;
}
.customised-mat-multiselect .mat-mdc-chip-set .mdc-evolution-chip-set__chips {
  min-height: 6px;
}
.mdc-list-item__primary-text {
  width: 100% !important;
}
.mat-mdc-checkbox label {
  padding: 10px 0 !important;
}


.mdc-checkbox {
  padding: 0 4px !important;
}
.mdc-checkbox__native-control {
  width: 20px;
  height: 20px;
}
.mdc-checkbox .mdc-checkbox__background {
  top: 0 !important;
  left: 0 !important;
}
.mat-mdc-checkbox-touch-target {
  width: 20px !important;
  height: 20px !important;
}
.mat-mdc-checkbox label {
  padding: 0 !important;
}
.mdc-checkbox .mdc-checkbox__native-control {
  width: 20px !important;
  height: 20px !important;
}
