.custom-skeleton {
  .skeleton-loader {
    margin-bottom: 0 !important;
    border-radius: 0 !important;
    height: 30px !important;
  }

  .skeleton-loader.progress-dark:before {
    background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.56), transparent) !important;
    animation-duration: 1s !important;
  }
}
