@import '../styles/responsive';
.custom-paginator .mat-mdc-paginator-container {
  min-height: 22px;
}

.custom-paginator .mat-mdc-form-field-flex {
  height: 22px;
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 0 !important;
}

.mat-mdc-paginator .mat-mdc-icon-button.mat-mdc-button-base {
  height: 22px;
  width: 22px;
  padding: 0;
  margin-right: 10px;
}

.mat-mdc-paginator-page-size {
  @include mobile {
    display: none !important;
  }
}
