.custom-expansion-panel .mat-expansion-panel-header {
  padding: 0;
}
.custom-expansion-panel .mat-mdc-tab:not(.mdc-tab--stacked) {
  height: 31px;
}

.mat-expansion-panel {
  background: #ffffff !important;
  border-radius: 4px 4px 0 0 !important;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: unset !important;
}

.mat-content.mat-content-hide-toggle {
  margin-right: 0 !important;
}

.custom-expansion-panel .mat-expansion-panel-body {
  padding: 0;
  border-left: 1px solid #007a72;
  border-right: 1px solid #007a72;
  border-bottom: 1px solid #007a72;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.white-expansion-panel .mat-expansion-panel-body {
  padding: 0;
  background-color: white;
}
