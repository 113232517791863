.menu-item .mat-accordion .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: unset !important;
}

.menu-item .mat-accordion .mat-expansion-panel {
  background: transparent !important;
}

.menu-item .mat-expansion-panel {
  background: #007a72 !important;
  border-radius: 0 !important;
}

.menu-item .mat-expansion-panel-spacing {
  margin: unset !important;
}

.menu-item .mat-accordion .mat-expansion-panel-header.mat-expanded {
  background: #007a72 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  height: 20px !important;
}

.menu-item .mat-accordion .mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
  margin-right: 0 !important;
}

.menu-item .mat-accordion .mat-expansion-panel-header.mat-expanded:focus {
  background: #007a72 !important;
}

.menu-item .mat-accordion .mat-expansion-panel-content {
  background: #007a72 !important;
}

.menu-item .mat-accordion .mat-expansion-panel-header {
  padding: 0 8px !important;
  height: 22px !important;
}

.menu-item .mat-accordion .mat-expansion-panel-body {
  padding: 0 0 10px 0 !important;
}
