.customised-mat-input {
  width: 100%;
  font-size: 14px !important;
}
.customised-mat-input .mdc-text-field--filled{
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.customised-mat-input .mdc-text-field {
  padding: 0 10px;
}
.customised-mat-input .mdc-text-field--filled.mdc-text-field--disabled {
  background-color: #EEEEEE;
}
.customised-mat-input .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding: 0;
}
.customised-mat-input .mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
  padding: 0;
}
.customised-mat-input .mdc-text-field--no-label .mat-mdc-form-field-infix {
  min-height: 35px;
}
.customised-mat-input .mat-mdc-form-field-bottom-align::before {
  height: 0;
}
.customised-mat-input-address .mdc-text-field--no-label .mat-mdc-form-field-infix {
  min-height: 36px;
}
.customised-mat-input .mat-mdc-form-field-error-wrapper {
  padding: 0;
}

.customised-mat-input .mat-mdc-form-field-infix {
  min-height: 36px;
}
.customised-mat-input .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 25px;
  z-index: 1;
}
.customised-mat-input .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control  {
  margin-top: 8px;
}
.customised-mat-input .mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align::before {
  font-size: 10px;
}
.customised-mat-input .mdc-text-field--filled .mdc-floating-label {
  font-size: 18px;
}
.customised-mat-input .mat-icon, .phone .mat-icon {
  font-size: 22px !important;
  margin-left: 7px;
  cursor: pointer;
}
.customised-mat-input .mat-mdc-form-field-error {
  font-size: 10px;
}
.mat-mdc-form-field-icon-suffix>.mat-icon {
  padding: 13px !important;
}
.customised-mat-input .mat-mdc-form-field-required-marker {
  display: none;
}

.phone .mat-mdc-form-field-infix {
  min-height: 50px;
}
.phone .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  margin-left: -100px;
}
.customised-login-input .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
//.customised-mat-input .mat-mdc-form-field-icon-suffix>.mat-icon{
//  padding: 0 10px 0 0;
//}
input::placeholder {
  color: #AEAEAE !important;
}
textarea::placeholder {
  color: #AEAEAE !important;
}

input::-webkit-input-placeholder {
  font-weight: 300;
}
textarea::-webkit-input-placeholder {
  font-weight: 300;
}
