.custom-tab .mat-mdc-tab {
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-right: 2px;
  border-bottom: 1px solid #007a72;
}
.custom-tab .mdc-tab-indicator--active {
  border-top: 1px solid #007a72;
  border-left: 1px solid #007a72;
  border-right: 1px solid #007a72;
  border-bottom: unset;
}
.custom-tab .mdc-tab {
  padding-left: 16px;
  padding-right: 16px;
}
.custom-tab .mdc-tab-indicator {
  display: none;
}
.custom-tab .mat-mdc-tab.mat-mdc-tab-disabled {
  opacity: 1;
  .mdc-tab__text-label {
    opacity: .4;
  }
}
